import { throttleRAF } from 'helpers/animation';
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import ModeIcon from '@mui/icons-material/Mode';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import UndoIcon from '@mui/icons-material/Undo';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Button,
  IconButton,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { InputLabel } from '@chainlit/react-components';

import EraserIcon from '../../assets/eraserIcon';

import {
  ReactSketchCanvas,
  ReactSketchCanvasRef,
  StrokePreview,
  useStrokePreview
} from '../../../../libs/react-sketch-canvas';

interface DrawOverlayInputProps {
  id: string;
  label: string;
  description: string;
  imgUrl: string;
  zoom: number;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  onCanvasReady: (
    canvasSize: [
      canvasWidth: number,
      canvasHeight: number,
      chromeWidth: number,
      chromeHeight: number
    ]
  ) => void;
}

interface DrawOverlayInputRef {
  getValue: () => Promise<{
    id: string;
    name: string;
    content: Blob;
    mimeType: string;
  }>;
}

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d')!;

// to help calculate the initial zoom level
const CHROME_WIDTH = 0;
const CHROME_HEIGHT = 150;

const animateSmoothly = throttleRAF();

const DrawOverlayInput = forwardRef(function DrawOverlayInput(
  {
    id,
    label,
    description,
    imgUrl,
    zoom,
    onCanvasReady,
    handleZoomIn,
    handleZoomOut
  }: DrawOverlayInputProps,
  ref: ForwardedRef<DrawOverlayInputRef>
) {
  const imageRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<ReactSketchCanvasRef>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const isMouseUser = useMediaQuery('(pointer: fine)');

  const originalWidth = imageRef.current?.naturalWidth || 0;
  const originalHeight = imageRef.current?.naturalHeight || 0;
  const imageWidth = originalWidth * zoom;
  const imageHeight = originalHeight * zoom;

  // all of this stuff is needed to support panning
  const hasPanSupport = !isMouseUser;
  const containerRef = useRef<HTMLDivElement>(null);
  const prevZoomRef = useRef(zoom);
  if (zoom !== prevZoomRef.current) {
    // each time the zoom changes we need to reset the scroll position
    const el = containerRef.current;
    if (hasPanSupport && el) {
      const dZoom = zoom / prevZoomRef.current;
      el.scrollLeft *= dZoom;
      el.scrollTop *= dZoom;
    }
    prevZoomRef.current = zoom;
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        async getValue(): Promise<{
          id: string;
          name: string;
          content: Blob;
          mimeType: string;
        }> {
          if (!inputRef.current || !imageRef.current) {
            throw new Error('Could not find react canvas or image element');
          }
          const format = 'png';
          const mimeType = `image/${format}`;
          const width = imageRef.current.naturalWidth || 0;
          const height = imageRef.current.naturalHeight || 0;
          const base64 = await inputRef.current.exportImage(format, {
            width,
            height
          });

          const content = await new Promise<Blob>((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              canvas.width = width;
              canvas.height = height;

              // In the drawing UI background color is white while stroke color is black for visibility.
              // But in the Stability AI API white seems to mean having the effect while black seems to mean
              // no effect. So inverting the color here before sending to the server.

              ctx.drawImage(img, 0, 0);
              const src = ctx.getImageData(0, 0, width, height);
              const dst = ctx.createImageData(width, height);

              for (let i = 0; i < src.data.length; i = i + 4) {
                dst.data[i] = 255 - src.data[i]; // R
                dst.data[i + 1] = 255 - src.data[i + 1]; // G
                dst.data[i + 2] = 255 - src.data[i + 2]; // B
                dst.data[i + 3] = src.data[i + 3]; // A
              }

              ctx.putImageData(dst, 0, 0);
              canvas.toBlob((blob) => resolve(blob!), mimeType);
            };
            img.onerror = (_event) => {
              // the argument here is not an error object, we need to create one
              reject(new Error('Error inverting image colors'));
            };

            img.src = base64;
          });
          return { id, name: '', content, mimeType };
        }
      };
    },
    [id]
  );

  const handleUndoClick = () => {
    inputRef.current?.undo();
  };
  type InteractionMode = 'draw' | 'erase' | 'pan';
  const [interactionMode, setInteractionMode] =
    useState<InteractionMode>('draw');

  const handleInteractionModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: InteractionMode | null
  ) => {
    if (newMode) setInteractionMode(newMode);
    inputRef.current?.eraseMode(newMode === 'erase');
  };

  const [strokeWidth, setStrokeWidth] = useState(50);
  const [brushAnchorEl, setBrushAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const handleBrushSize = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBrushAnchorEl(event.currentTarget);
  };
  const handleCloseBrushSize = () => {
    setBrushAnchorEl(null);
  };

  const { cursorTop, cursorLeft, cursorFollowerRef, isDrawing } =
    useStrokePreview(inputRef);

  // all of this is only for better mobile support
  const isPanning = useRef(false);
  const onPanStart = (e: React.PointerEvent) => {
    isPanning.current = true;
    animateSmoothly(() => doPan(e));
  };
  const onPanEnd = (_e: React.PointerEvent) => {
    isPanning.current = false;
  };
  const onPan = (e: React.PointerEvent) => {
    animateSmoothly(() => doPan(e));
  };
  const doPan = (e: React.PointerEvent) => {
    const el = containerRef.current;
    if (el) {
      // we're panning based on distance from the center
      const centerX = el.offsetLeft + el.clientWidth / 2;
      const centerY = el.offsetTop + el.clientHeight / 2;
      const dx = e.clientX - centerX;
      const dy = e.clientY - centerY;
      // mellow out the speed at which it pans using a log function
      const dxSign = Math.sign(dx);
      const dySign = Math.sign(dy);
      const ddx = dxSign * Math.log2(Math.abs(dx)) || 0;
      const ddy = dySign * Math.log2(Math.abs(dy)) || 0;
      el.scrollLeft += ddx;
      el.scrollTop += ddy;
    }

    // keep moving in the same direction until they let up their finger
    if (isPanning.current) {
      animateSmoothly(() => doPan(e));
    }
  };

  return (
    <Stack gap="1em" padding="24px 20px" height={{ xs: '100%', sm: 'auto' }}>
      <Stack>
        <InputLabel label={label} />
        <FormHelperText>{description}</FormHelperText>
      </Stack>
      <Stack
        direction="row"
        height="50px"
        gap=".5em"
        padding="0 .5em .5em 0"
        alignItems="center"
      >
        <ToggleButtonGroup
          color="primary"
          value={interactionMode}
          exclusive
          onChange={handleInteractionModeChange}
        >
          <ToggleButton value="draw">
            <Tooltip
              title={t('components.molecules.drawOverlayInput.tooltips.draw')}
            >
              <Stack>
                <ModeIcon />
              </Stack>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="erase">
            <Tooltip
              title={t('components.molecules.drawOverlayInput.tooltips.erase')}
            >
              <Stack>
                <EraserIcon />
              </Stack>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        <Tooltip
          title={t('components.molecules.drawOverlayInput.tooltips.brushSize')}
        >
          <Stack>
            <IconButton
              aria-label={t(
                'components.molecules.drawOverlayInput.tooltips.brushSize'
              )}
              onClick={handleBrushSize}
            >
              <div
                style={{
                  borderRadius: 12,
                  background: theme.palette.text.secondary,
                  width: 25,
                  height: 25
                }}
              />
            </IconButton>
          </Stack>
        </Tooltip>
        <Tooltip
          title={t('components.molecules.drawOverlayInput.tooltips.undo')}
        >
          <Stack>
            <IconButton
              aria-label={t(
                'components.molecules.drawOverlayInput.tooltips.undo'
              )}
              onClick={handleUndoClick}
            >
              <UndoIcon />
            </IconButton>
          </Stack>
        </Tooltip>

        {hasPanSupport && (
          <ToggleButtonGroup
            color="primary"
            value={interactionMode}
            exclusive
            onChange={handleInteractionModeChange}
          >
            <ToggleButton value="pan" sx={{ border: 'none' }}>
              <Tooltip
                title={t('components.molecules.drawOverlayInput.tooltips.pan')}
              >
                <Stack>
                  <OpenWithIcon />
                </Stack>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Tooltip
          title={t('components.molecules.drawOverlayInput.tooltips.zoomIn')}
        >
          <Stack>
            <IconButton
              aria-label={t(
                'components.molecules.drawOverlayInput.tooltips.zoomIn'
              )}
              onClick={handleZoomIn}
            >
              <ZoomInIcon />
            </IconButton>
          </Stack>
        </Tooltip>
        <Tooltip
          title={t('components.molecules.drawOverlayInput.tooltips.zoomOut')}
        >
          <Stack>
            <IconButton
              aria-label={t(
                'components.molecules.drawOverlayInput.tooltips.zoomOut'
              )}
              onClick={handleZoomOut}
            >
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Tooltip>
      </Stack>
      <Stack
        ref={containerRef}
        overflow={{ xs: 'scroll', sm: 'visible' }}
        position="relative"
        onPointerDown={interactionMode === 'pan' ? onPanStart : undefined}
        onPointerMove={interactionMode === 'pan' ? onPan : undefined}
        onPointerUp={interactionMode === 'pan' ? onPanEnd : undefined}
      >
        <img
          src={imgUrl}
          onLoad={(e) => {
            onCanvasReady([
              e.currentTarget.naturalWidth,
              e.currentTarget.naturalHeight,
              CHROME_WIDTH,
              CHROME_HEIGHT
            ]);
          }}
          ref={imageRef}
          width={imageWidth}
          height={imageHeight}
        />
        {isMouseUser && isDrawing && (
          <StrokePreview
            top={cursorTop}
            left={cursorLeft}
            strokeWidth={strokeWidth * zoom}
            ref={cursorFollowerRef}
          />
        )}
        <ReactSketchCanvas
          ref={inputRef}
          readOnly={interactionMode === 'pan'}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            opacity: '0.3',
            width: imageWidth,
            height: imageHeight
          }}
          withViewBox={true}
          viewBoxWidth={originalWidth}
          viewBoxHeight={originalHeight}
          eraserWidth={strokeWidth}
          strokeWidth={strokeWidth}
          strokeColor="black"
          canvasColor="white"
        />
      </Stack>
      {brushAnchorEl && (
        <BrushSizePicker
          anchorEl={brushAnchorEl}
          open={!!brushAnchorEl}
          strokeWidth={strokeWidth}
          setStrokeWidth={setStrokeWidth}
          handleCloseBrushSize={handleCloseBrushSize}
        />
      )}
    </Stack>
  );
});

type BrushSizePickerProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  strokeWidth: number;
  handleCloseBrushSize: () => void;
  setStrokeWidth: (width: number) => void;
};

const BrushSizePicker = ({
  anchorEl,
  open,
  handleCloseBrushSize,
  strokeWidth,
  setStrokeWidth
}: BrushSizePickerProps) => {
  const sliderRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const [tempStrokeWidth, setTempStrokeWidth] = useState(strokeWidth);

  const handleOk = () => {
    if (sliderRef.current) {
      setStrokeWidth(parseInt(sliderRef.current.value, 10));
      handleCloseBrushSize();
    }
  };

  return (
    <Popover anchorEl={anchorEl} open={open} onClose={handleCloseBrushSize}>
      <Stack padding="1em 1.5em" gap="1em">
        <Typography variant="h6" gutterBottom>
          {t('components.molecules.drawOverlayInput.headings.brushSize')}
        </Typography>
        <Stack width="200px" gap=".5em" alignItems="center" flexDirection="row">
          <input
            type="range"
            ref={sliderRef}
            min="10"
            max="150"
            value={tempStrokeWidth}
            style={{ width: '100%' }}
            onChange={(e) => {
              setTempStrokeWidth(parseInt(e.target.value, 10));
            }}
          />
        </Stack>
        <Stack
          width="200px"
          height="160px"
          border={`1px solid ${theme.palette.text.primary}`}
          justifyContent="center"
          alignItems="center"
        >
          <div
            style={{
              borderRadius: tempStrokeWidth / 2,
              background: theme.palette.text.primary,
              width: tempStrokeWidth,
              height: tempStrokeWidth
            }}
          />
        </Stack>
        <Stack justifyContent="end" gap=".5em" direction="row">
          <Button color="inherit" variant="outlined" onClick={handleOk}>
            {t('components.molecules.drawOverlayInput.ok')}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};

export { DrawOverlayInput };
export type { DrawOverlayInputRef };

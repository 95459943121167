import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'sonner';

import {
  IAction,
  IFeedback,
  IMessageElement,
  IPreference,
  IReroll,
  IStep,
  accessTokenState,
  advancedRerollState,
  messagesState,
  sendEvent,
  toolsState,
  updateMessageById,
  useChatData,
  useChatInteract,
  useChatMessages,
  useChatSession
} from '@chainlit/react-client';

import { apiClientState } from 'state/apiClient';
import { generationShareModalState } from 'state/generationShareModal';
import { IProjectSettings, projectSettingsState } from 'state/project';

import {
  ExecuteAdvancedRerollButton,
  ExecuteMultiAdvancedRerollButton,
  ToolListButton
} from '../Tools';
import MessageContainer from './container';
import WelcomeScreen from './welcomeScreen';

interface MessagesProps {
  autoScroll: boolean;
  projectSettings?: IProjectSettings;
  setAutoScroll: (autoScroll: boolean) => void;
}

const Messages = ({
  autoScroll,
  projectSettings,
  setAutoScroll
}: MessagesProps): JSX.Element => {
  const { elements, askUser, avatars, loading, actions } = useChatData();
  const { messages } = useChatMessages();
  const { callAction, reroll, useTool, updatePreference, checkToolValidity } =
    useChatInteract();
  const { idToResume } = useChatSession();
  const accessToken = useRecoilValue(accessTokenState);
  const advRerollState = useRecoilValue(advancedRerollState);
  const setMessages = useSetRecoilState(messagesState);
  const apiClient = useRecoilValue(apiClientState);
  const tools = useRecoilValue(toolsState);
  const setGenerationShareModalState = useSetRecoilState(
    generationShareModalState
  );
  const pSettings = useRecoilValue(projectSettingsState);

  const { t } = useTranslation();

  const callActionWithToast = useCallback(
    (action: IAction) => {
      const promise = callAction(action);
      if (promise) {
        toast.promise(promise, {
          loading: `${t('components.organisms.chat.Messages.index.running')} ${
            action.name
          }`,
          success: (res) => {
            if (res.response) {
              return res.response;
            } else {
              return `${action.name} ${t(
                'components.organisms.chat.Messages.index.executedSuccessfully'
              )}`;
            }
          },
          error: (res) => {
            if (res.response) {
              return res.response;
            } else {
              return `${action.name} ${t(
                'components.organisms.chat.Messages.index.failed'
              )}`;
            }
          }
        });
      }
    },
    [callAction]
  );

  const onFeedbackUpdated = useCallback(
    async (message: IStep, onSuccess: () => void, feedback: IFeedback) => {
      try {
        toast.promise(apiClient.setFeedback(feedback, accessToken), {
          loading: t('components.organisms.chat.Messages.index.updating'),
          success: (res) => {
            setMessages((prev) =>
              updateMessageById(prev, message.id, {
                ...message,
                feedback: {
                  ...feedback,
                  id: res.feedbackId
                }
              })
            );
            onSuccess();
            return t(
              'components.organisms.chat.Messages.index.feedbackUpdated'
            );
          },
          error: (err) => {
            return <span>{err.message}</span>;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const onPreferenceUpdated = useCallback(
    async (preference_data: IPreference, onSuccess: () => void) => {
      const promise = updatePreference(preference_data);
      if (!promise) {
        return;
      }

      try {
        toast.promise(promise, {
          loading: t(
            'components.organisms.chat.Messages.index.updating_preference'
          ),
          success: () => {
            onSuccess();
            return t(
              'components.organisms.chat.Messages.index.updated_preference'
            );
          },
          error: () => {
            return t(
              'components.organisms.chat.Messages.index.preference_failed'
            );
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    [updatePreference]
  );

  const onReroll = useCallback(
    async (reroll_data: IReroll) => {
      const promise = reroll(reroll_data);
      if (!promise) {
        return;
      }

      try {
        toast.promise(promise, {
          error: (result) => {
            return result.message;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    [reroll]
  );

  const onShareElement = useCallback(
    async (element_id: string, mime: string, sharedUuid?: string) => {
      setGenerationShareModalState((prev) => ({
        ...prev,
        open: true,
        elementId: element_id,
        type: mime,
        sharedUuid: sharedUuid || ''
      }));
    },
    [setGenerationShareModalState]
  );

  const toolListButtonFn = useCallback(
    (
      selectedElementIds: string[],
      selectedElementUrl: string,
      selectedElementType: string,
      forStepId: string,
      configureAdvancedReroll: boolean,
      selectedElementInfoForTools?: string,
      isUser?: boolean,
      buttonStyle?: object,
      iconStyle?: object,
      tooltip?: string
    ) => (
      <ToolListButton
        instance={
          configureAdvancedReroll ? 'image-button' : 'advanced-menu-button'
        }
        configureAdvancedReroll={configureAdvancedReroll}
        selectedElementIds={selectedElementIds}
        selectedElementUrl={selectedElementUrl}
        selectedElementType={selectedElementType}
        forStepId={forStepId}
        selectedElementInfoForTools={selectedElementInfoForTools}
        isUser={isUser}
        buttonStyle={buttonStyle}
        iconStyle={iconStyle}
        tooltip={tooltip}
      />
    ),
    []
  );

  const executeAdvancedRerollButtonFn = useCallback(
    (
      selectedElementType: string,
      selectedElementId: string,
      forStepId: string,
      onAdvancedRerollAction?: (element_id: string, step_id: string) => void
    ) => (
      <ExecuteAdvancedRerollButton
        selectedElementType={selectedElementType}
        selectedElementId={selectedElementId}
        forStepId={forStepId}
        onAdvancedRerollAction={onAdvancedRerollAction}
      />
    ),
    []
  );

  const executeMultiAdvancedRerollButtonFn = useCallback(
    (
      selectedElementType: string,
      selectedElements: IMessageElement[],
      onMultiAdvancedRerollAction?: (elements: IMessageElement[]) => void
    ) => (
      <ExecuteMultiAdvancedRerollButton
        selectedElementType={selectedElementType}
        selectedElements={selectedElements}
        onMultiAdvancedRerollAction={onMultiAdvancedRerollAction}
      />
    ),
    []
  );

  const checkAdvancedRerollToolValidity = useCallback(
    (elementType: string, elementInfoForTools: string) => {
      if (!advRerollState) {
        return false;
      }

      return checkToolValidity(
        advRerollState.toolName,
        advRerollState.toolObject,
        elementType,
        true,
        elementInfoForTools
      );
    },
    [checkToolValidity, advRerollState]
  );

  const onAdvancedReroll = useCallback(
    async (element_id: string, step_id: string) => {
      if (!advRerollState) {
        toast.info('Select a tool to use');
        return;
      }

      const promise = useTool(
        advRerollState.modality,
        advRerollState.toolName,
        step_id,
        element_id,
        advRerollState.args
      );
      if (!promise) {
        return;
      }

      let tool_name = advRerollState?.toolName;
      if (tool_name && tool_name in tools[advRerollState.modality][tool_name]) {
        tool_name = tools[advRerollState.modality][tool_name].title;
      }

      toast.promise(promise, {
        error: (result) => result.message || 'Error!'
      });
    },
    [reroll, advRerollState]
  );

  return !idToResume &&
    !messages.length &&
    projectSettings?.ui.show_readme_as_default ? (
    <WelcomeScreen
      variant="app"
      markdown={projectSettings?.markdown}
      allowHtml={projectSettings?.features?.unsafe_allow_html}
      latex={projectSettings?.features?.latex}
    />
  ) : (
    <MessageContainer
      avatars={avatars}
      loading={loading}
      askUser={askUser}
      actions={actions}
      elements={elements}
      messages={messages}
      autoScroll={autoScroll}
      onFeedbackUpdated={onFeedbackUpdated}
      onPreferenceUpdated={onPreferenceUpdated}
      onReroll={onReroll}
      checkAdvancedRerollToolValidity={checkAdvancedRerollToolValidity}
      onAdvancedReroll={!advRerollState ? undefined : onAdvancedReroll}
      onShareElement={
        pSettings?.features.allow_share ? onShareElement : undefined
      }
      callAction={callActionWithToast}
      setAutoScroll={setAutoScroll}
      sendAnalyticsEvent={sendEvent}
      t={t}
      toolListButtonFn={toolListButtonFn}
      executeAdvancedRerollButtonFn={executeAdvancedRerollButtonFn}
      executeMultiAdvancedRerollButtonFn={executeMultiAdvancedRerollButtonFn}
    />
  );
};

export default Messages;

import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import size from 'lodash/size';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { EditOutlined } from '@mui/icons-material';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import { IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  IThread,
  ThreadHistory,
  useChatInteract,
  useChatSession
} from '@chainlit/react-client';
import { TextInput, grey } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

import { settingsState } from 'state/settings';

import { DeleteThreadButton } from './DeleteThreadButton';

interface Props {
  threadHistory?: ThreadHistory;
  error?: string;
  fetchThreads: () => void;
  isFetching: boolean;
  isLoadingMore: boolean;
  onThreadRename: (threadId: string, threadName: string) => void;
}

const ThreadList = ({
  threadHistory,
  error,
  fetchThreads,
  isFetching,
  isLoadingMore,
  onThreadRename
}: Props) => {
  const settings = useRecoilValue(settingsState);
  const { idToResume } = useChatSession();
  const { clear } = useChatInteract();
  const navigate = useNavigate();
  const [threadIdBeingNameEdited, setThreadIdBeingNameEdited] = useState<
    null | string
  >(null);
  const [threadName, setThreadName] = useState('');

  const startEditingThreadName = (thread: IThread) => {
    return (e: React.MouseEvent) => {
      setThreadName(thread.metadata?.name || '');
      setThreadIdBeingNameEdited(thread.id);

      e.stopPropagation();
      e.preventDefault();
    };
  };

  if (isFetching || (!threadHistory?.timeGroupedThreads && isLoadingMore)) {
    return (
      <>
        {[1, 2, 3].map((index) => (
          <Box key={`threads-skeleton-${index}`} sx={{ px: 1.5, mt: 2 }}>
            <Skeleton width={100} />
            {[1, 2].map((childIndex) => (
              <Stack
                key={`threads-skeleton-${index}-${childIndex}`}
                sx={{
                  py: 2,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1.5
                }}
              >
                <Skeleton width={30} />
                <Skeleton width={'100%'} />
              </Stack>
            ))}
          </Box>
        ))}
      </>
    );
  }

  if (error) {
    return (
      <Alert sx={{ mx: 1.5 }} severity="error">
        {(error as any).message}
      </Alert>
    );
  }

  if (settings.chatHistoryOptedOut) {
    return (
      <Alert variant="standard" sx={{ mx: 1.5 }} severity="info">
        <Translator path="components.organisms.threadHistory.sidebar.ThreadList.optedOut" />
      </Alert>
    );
  }

  if (!threadHistory) {
    return null;
  }

  if (size(threadHistory?.timeGroupedThreads) === 0) {
    return (
      <Alert variant="standard" sx={{ mx: 1.5 }} severity="info">
        <Translator path="components.organisms.threadHistory.sidebar.ThreadList.empty" />
      </Alert>
    );
  }

  const handleDeleteThread = (threadId: string) => {
    if (threadId === idToResume) {
      clear();
    }
    if (threadId === threadHistory.currentThreadId) {
      navigate('/');
    }
    fetchThreads();
  };

  const updateThreadName = () => {
    if (threadName.trim() && threadIdBeingNameEdited) {
      onThreadRename(threadIdBeingNameEdited, threadName.trim());
    }

    setThreadIdBeingNameEdited(null);
  };

  const handleTextInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      updateThreadName();
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThreadName(e.target.value);
  };

  return (
    <>
      <List
        sx={{
          px: 1,
          height: 0,
          bgcolor: 'background.paper',
          '& ul': { padding: 0 }
        }}
        subheader={<li />}
      >
        {map(threadHistory.timeGroupedThreads, (items, index) => {
          return (
            <li key={`section-${index}`}>
              <ul>
                <ListSubheader sx={{ px: 1.5 }}>
                  <Typography
                    sx={{
                      py: 1,
                      color: 'text.secondary',
                      fontWeight: 600,
                      fontSize: '12px',
                      backgroundColor: (theme) => theme.palette.background.paper
                    }}
                  >
                    {index}
                  </Typography>
                </ListSubheader>
                {map(items, (thread) => {
                  const isResumed =
                    idToResume === thread.id && !threadHistory.currentThreadId;

                  const isSelected =
                    isResumed || threadHistory.currentThreadId === thread.id;

                  return (
                    <Stack
                      component={
                        threadIdBeingNameEdited === thread.id ? Box : Link
                      }
                      key={`thread-${thread.id}`}
                      id={`thread-${thread.id}`}
                      sx={(theme) => ({
                        textDecoration: 'none',
                        cursor: 'pointer',
                        p: 1.5,
                        mb: 0.5,
                        gap: 0.5,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: 1,
                        backgroundColor: isSelected
                          ? theme.palette.mode === 'dark'
                            ? grey[800]
                            : 'grey.200'
                          : theme.palette.background.paper,
                        '&:hover': {
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? grey[800]
                              : 'grey.200',
                          '.edit-button': {
                            display: 'inline-flex'
                          }
                        }
                      })}
                      to={
                        isResumed || threadIdBeingNameEdited === thread.id
                          ? ''
                          : `/thread/${thread.id}`
                      }
                    >
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack
                          sx={{
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 1.5,
                            flex: 1,
                            overflow: 'hidden',
                            color: (theme) => theme.palette.text.primary
                          }}
                        >
                          <ChatBubbleOutline
                            sx={{
                              width: '16px',
                              height: '16px'
                            }}
                          />
                          {threadIdBeingNameEdited === thread.id ? (
                            <TextInput
                              value={threadName}
                              onChange={handleTextInputChange}
                              id={`thread-name-${thread.id}`}
                              sx={{ p: 0 }}
                              onKeyDown={handleTextInputKeyDown}
                              autoFocus={true}
                              onBlur={updateThreadName}
                              maxLength={64}
                            />
                          ) : (
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {capitalize(thread.metadata?.name || 'Unknown')}
                            </Typography>
                          )}
                        </Stack>
                        {threadIdBeingNameEdited !== thread.id && (
                          <IconButton
                            size="small"
                            onClick={startEditingThreadName(thread)}
                            sx={{ p: '2px', display: 'none' }}
                            className="edit-button"
                          >
                            <EditOutlined sx={{ width: 16, height: 16 }} />
                          </IconButton>
                        )}
                        {isSelected ? (
                          <DeleteThreadButton
                            threadId={thread.id}
                            onDelete={() => handleDeleteThread(thread.id)}
                          />
                        ) : null}
                      </Stack>
                    </Stack>
                  );
                })}
              </ul>
            </li>
          );
        })}
        <Stack alignItems={'center'}>
          <CircularProgress
            id={'chat-history-loader'}
            size={30}
            sx={{ my: 1, opacity: isLoadingMore ? 1 : 0 }}
          />
        </Stack>
      </List>
    </>
  );
};

export { ThreadList };
